<template>
  <a-layout class="navigation">
    <!-- Header Menu area -->
    <TopNavbar @logout="handleLogout" />
    <!-- Header Menu area -->
    <!-- Side bar menu area -->
    <div>
      <SideNavbar />
    </div>
    <!-- Side bar menu area -->
    <a-layout class="p-4">
      <a-layout-content class="layout-container">
        <div v-if="showBackOnline">
          <router-view />
        </div>
        <div v-else class="navigation__offline">
          <ApiOutlined class="navigation__offline--icon" />
          <h3>Connect to the internet</h3>
          <h4>You're offline. Check your connection</h4>
          <br />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
  <!-- Sidebar menu area -->
</template>
<script>
import { defineComponent, reactive, watch, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useConnectionStatus from '@/use/connection-status'
import TopNavbar from '@/components/common/TopNavbar'
import SideNavbar from '@/components/common/SideNavbar'
import { ApiOutlined } from '@ant-design/icons-vue'
import useNotifier from '@/use/notifier'

export default defineComponent({
  components: {
    TopNavbar,
    ApiOutlined,
    SideNavbar
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    useNotifier()
    const state = reactive({
      collapsed: false,
      selectedKeys: [route.name]
    })
    const { showBackOnline } = useConnectionStatus()
    const changeRoute = ({ key }) => router.push({ name: key }).catch(() => {})
    const handleLogout = () => {
      store.dispatch('auth/LogoutUser')
    }
    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal
      }
    )
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed
      state.openKeys = state.collapsed ? [] : state.preOpenKeys
    }

    return {
      ...toRefs(state),
      toggleCollapsed,
      changeRoute,
      showBackOnline,
      handleLogout
    }
  }
})
</script>
<style></style>
