import { reactive, watch, onMounted, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default function useConnectionStatus() {
  const state = reactive({
    onLine: navigator.onLine,
    showBackOnline: false
  })
  const route = useRoute()
  const router = useRouter()
  const updateOnlineStatus = (e) => {
    const { type } = e
    state.onLine = type === 'online'
  }
  const checkInterConnectivity = (isOnLine) => {
    //    console.log("isOnLine | ", isOnLine)
    if (isOnLine) {
      state.showBackOnline = true
      let currentRoute = route.path
      // console.log("currentRoute | ", currentRoute)
      router.push(currentRoute).catch(() => {})
    } else {
      state.showBackOnline = false
      // this.showErrorAlert("Connect to the internet. You're offline. Check your connection");
    }
  }

  // since there is no created hook all created stuffs are done in setup
  // checking to see if user is connected on component created
  if (checkInterConnectivity) {
    checkInterConnectivity(navigator.onLine)
  }

  // if (state.showBackOnline) {
  //   if (this.IsAgentProfileAvailable) {
  //     this.agentProfile = this.IsAgentProfileAvailable
  //   }
  // }
  // mounted created the default js event listener to trigger the online/offline event
  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)
  })
  watch(
    () => state.onLine,
    (value) => {
      if (value) {
        state.showBackOnline = true
        let currentRoute = route.path
        // console.log("currentRoute | ", currentRoute)
        router.push(currentRoute).catch(() => {})
      } else {
        state.showBackOnline = false
      }
    }
  )
  return { ...toRefs(state) }
}
