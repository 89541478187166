<template>
  <div>
    <a-layout-header class="navigation__header bg-white">
      <div class="navigation__header--logo">
        <!-- <MenuOutlined
          class="trigger text-2xl"
          :style="{ cursor: 'pointer' }"
          @click="showDrawer"
        /> -->
        <img src="/logo.svg" alt="" />
      </div>
      <div>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            {{ currentUser.name.toUpperCase() }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="handleLogout"> Logout </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </a-layout-header>
    <a-menu
      v-model:selectedKeys="selectedKeys"
      class="container navigation__submenu"
      mode="horizontal"
      :style="{ borderTop: '1px solid rgb(243 236 236)', padding: '.2em 2em' }"
      @click="changeRoute"
    >
      <a-menu-item key="Dashboard"> Dashboard </a-menu-item>
      <a-menu-item key="Collections"> Collections </a-menu-item>
      <!-- <a-menu-item key="Looks"> Looks </a-menu-item> -->
      <a-menu-item key="Settings"> Settings </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { DownOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {
    // MenuOutlined,
    DownOutlined
  },
  emits: ['logout'],
  setup(props, context) {
    const route = useRoute()
    const router = useRouter()
    let selectedKeys = ref([route.name])
    const handleLogout = () => {
      context.emit('logout')
    }
    const store = useStore()
    const currentUser = store.getters['auth/GetCurrentUser']
    const showDrawer = () => {
      store.commit('SET_DRAWER_STATE', true)
    }
    const changeRoute = ({ key }) => router.push({ name: key }).catch(() => {})
    return { handleLogout, showDrawer, selectedKeys, changeRoute, currentUser }
  }
}
</script>

<style lang="scss" scoped></style>
