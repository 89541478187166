<template>
  <div>
    <a-drawer
      placement="left"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :wrap-style="{ position: 'absolute' }"
      @close="onClose"
    >
      <div class="mx-auto text-center w-full bg-gray-100 py-6">
        <img src="/img/avatar.png" alt="" class="w-28 h-auto mx-auto" />
        <p class="text-center mt-4 text-lg text-gray-800">
          {{ currentUser.username }}
        </p>
        <p class="text-center text-sm text-gray-800">
          {{ currentUser.email }}
        </p>
      </div>
      <div class="text-left px-6 py-2"></div>
    </a-drawer>
  </div>
</template>

<script>
import { reactive, onMounted, computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const localState = reactive({
      visible: false,
      selectedKeys: [route.name]
    })
    const store = useStore()
    console.log(store.state.drawerState)
    localState.visible = computed(() => store.state.drawerState)
    let currentUser = computed(() => store.getters['auth/GetCurrentUser'])
    const onClose = () => {
      store.commit('SET_DRAWER_STATE', false)
    }

    onMounted(() => {
      store.commit('SET_DRAWER_STATE', false)
    })
    return { ...toRefs(localState), onClose, currentUser }
  }
}
</script>

<style lang="scss" scoped></style>
